import _includes from "utilise/includes";
import _is from "utilise/is";
var exports = {};
exports = {
  header: "text/plain",

  check(res) {
    return !includes(".html")(res.name) && !includes(".css")(res.name) && is.str(res.body);
  }

};
const includes = _includes,
      is = _is;
export default exports;